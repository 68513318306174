import * as React from 'react'
import {input} from '../styles/search-input.module.scss'

const SearchInput = ({pageTitle, children}) => {
    return (<div>
            <input className={input} placeholder="جستجو"/>
        </div>
    )
};
export default SearchInput
