import * as React from 'react'
import {container, main} from '../styles/layout.module.scss'
import Helmet from 'react-helmet';
import HeaderMenu from "./header-menu";

const Layout = ({pageTitle, children}) => {
    return (<div className={container}>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
            </Helmet>
            <HeaderMenu/>
            <main className={main}>
                <title>{pageTitle}</title>
                {children}
            </main>
        </div>
    )
};
export default Layout
