import * as React from 'react'
import {container, homeLink} from '../styles/header-menu.module.scss'
import {Link} from 'gatsby'
import SearchInput from "./search-input";

const HeaderMenu = ({pageTitle, children}) => {
    return (<div>
            <nav className={container}>
                <Link to="/" className={homeLink}>خانه</Link>
                <div><SearchInput/></div>
            </nav>
        </div>
    )
};
export default HeaderMenu
